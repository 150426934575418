import React, { useState } from "react";

export default function Benefits() {
  const [hoveredBenefit, setHoveredBenefit] = useState(null);

  const benefits = [
    {
      title: "SKILL DEVELOPMENT",
      description:
        "The program offers opportunities for participants to develop a diverse set of skills, including leadership, communication, event management, and networking, which are invaluable for personal and professional growth.",
    },
    {
      title: "INTERNSHIP OPPORTUNITIES",
      description:
        "Program opens doors to exciting internship opportunities. Gain valuable experience, expand your network, and elevate your career in technology.",
    },
    {
      title: "RECOGNITION AND CERTIFICATE",
      description:
        "Quark acknowledges the efforts of its Ambassadors through special recognition during the fest and beyond, giving them a sense of pride and accomplishment.",
    },
    {
      title: "INDUSTRY EXPOSURE",
      description:
        "Campus Ambassadors have the opportunity to network with industry professionals, guest speakers, and renowned personalities during the fest, which can lead to future career prospects.",
    },
  ];

  return (
    <>
      <hr className="border-pinkColor border-2 w-[59%] ml-auto" />
      <div className="w-screen min-h-screen py-4 md:py-10 text-center flex flex-col items-center justify-center gap-2 select-none relative">
        <h1 className="text-lg sm:text-xl md:text-3xl font-semibold px-4 py-1 border-2 rounded-3xl sm:mb-2">
          Benefits
        </h1>
        <div className="text-4xl sm:text-5xl md:text-6xl font-league-gothic flex flex-col items-center justify-center mt-7 gap-4 md:gap-6 w-full max-w-4xl px-4">
          {benefits.map((benefit, index) => (
            <button
              key={index}
              className="w-full border-2 border-transparent rounded-xl p-2 hover:bg-white hover:border-white hover:[box-shadow:0px_0px_20px_rgba(224,65,155,1)] relative transition-all duration-300 ease-in-out min-h-[90px] md:min-h-[110px] overflow-hidden"
              onMouseEnter={() => setHoveredBenefit(index)}
              onMouseLeave={() => setHoveredBenefit(null)}
            >
              <span
                className={`block transition-all duration-300 ease-in-out ${
                  hoveredBenefit === index ? "opacity-0" : "opacity-100"
                }`}
              >
                {benefit.title}
              </span>
              <span
                className={`absolute inset-0 flex items-center justify-center text-benefitColor text-base sm:text-lg md:text-xl lg:text-2xl p-4 transition-all duration-300 ease-in-out overflow-y-auto ${
                  hoveredBenefit === index ? "opacity-100" : "opacity-0"
                }`}
              >
                {benefit.description}
              </span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
}
