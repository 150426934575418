import React from "react";

const Card = ({ testimonial, nomen }) => {
    return (
      <div className="bg-pink-500 p-6 rounded-lg text-white h-[324px] mx-4">
        <div className="flex justify-center items-center mb-4">
          <div className="rounded-full bg-black p-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 14l9-5-9-5-9 5 9 5zm0 5l9-5-9-5-9 5 9 5z" />
            </svg>
          </div>
        </div>
        <p className="text-center text-sm h-2/3">{testimonial}</p>
        <p className="text-center mt-4 font-bold bottom-0">{nomen}</p>
      </div>
    );
  };

export default Card;
