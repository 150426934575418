import React, { useState } from "react";
import Accordion from "./Accordion";

export default function FAQs() {
  const [openIndex, setOpenIndex] = useState(null);

  const faqItems = [
    {
      title: "Who can be campus ambassadors?",
      answer: "Any College Student with a valid College Student Identity Card",
    },
    {
      title: "What my responsibilities as a campus ambassador?",
      answer: "As a Campus Ambassador, your primary responsibility is to promote Kaizen and its events within your college. You'll use online and offline methods to spread awareness, leverage social media, organize virtual or in-person sessions, and encourage student participation. We will showcase your contributions on the official website, and you'll have a chance to win exciting cash prizes and gift vouchers/official merch based on your efforts!",
    },
    {
      title: "Can there be multiple CAs from the same college?",
      answer:
        "Yes! Depending on the size of the college, Quark can appoint multiple College Ambassadors",
    },
    {
      title: "How do I know if I am selected as a campus ambassador?",
      answer: "To know if you got selected, keep an eye on your inbox. We will send official emails on a rolling basis to the students chosen as Campus Ambassadors",
    },
    {
      title: "How much effort is required to reach the top of the leaderboard?",
      answer: "Consistently meeting deadlines and completing your tasks will keep you in the running for top Campus Ambassador positions. High performers stand a chance to win exciting rewards, including internship opportunities and monetary concession for Quark 2024.",
    },
    {
      title: "What are the requirements to earn the Campus Ambassador certificate?",
      answer: "To receive a CA Certificate, Campus Ambassadors must exceed the minimum cut-off points set by the QUARK, BITS Goa.",
    },
  ];

  return (
    <>
      <hr className="border-pinkColor border-2 w-[69%]" />
      <div className="py-20">
        <div className="w-screen h-screen py-4 md:py-10 text-center flex flex-col items-center justify-center gap-5 md:gap-10 select-none">
          <h1 className="text-lg sm:text-xl md:text-3xl font-semibold px-4 py-1 border-2 rounded-3xl">
            F.A.Qs
          </h1>
          <ul className="text-left text-lg md:text-xl font-league-gothic w-[90%] md:w-[60%]">
            {faqItems.map((item, index) => (
              <li
                key={index}
                className="border-faqColor flex flex-col justify-between border-2 p-2 pt-5 w-full rounded-xl"
              >
                <Accordion
                  title={item.title}
                  answer={item.answer}
                  isOpen={index === openIndex}
                  onToggle={() =>
                    setOpenIndex(index === openIndex ? null : index)
                  }
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
