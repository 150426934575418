import React from "react";
import { Link } from "react-scroll";
import Home from "../assets/Home/Home.svg";
import Leaderboard from "../assets/Home/Leaderboard.svg";
import ContactUs from "../assets/Home/ContactUs.svg";
import FAQs from "../assets/Home/FAQs.svg";

export default function Sidebar() {
  return (
    <div className="hidden md:flex flex-col justify-evenly bg-white-35 border-2 border-border-gradient fixed rounded-full top-44 right-4 w-auto h-[48%] p-2 z-10">
      <Link
        to="hero"
        smooth={true}
        offset={0} // Adjust for fixed navbar
        duration={500}
        className="cursor-pointer my-2"
      >
        <img src={Home} alt="Home" className="w-12 h-auto" />
      </Link>
      <a href="/leaderboard" className="cursor-pointer my-2">
        <img src={Leaderboard} alt="Leaderboard" className="w-12 h-auto" />
      </a>
      <Link
        to="contactus"
        smooth={true}
        offset={-70} // Adjust for fixed navbar
        duration={500}
        className="cursor-pointer my-2"
      >
        <img src={ContactUs} alt="ContactUs" className="w-12 h-auto" />
      </Link>
      <Link
        to="faqs"
        smooth={true}
        offset={-40} // Adjust for fixed navbar
        duration={500}
        className="cursor-pointer my-2"
      >
        <img src={FAQs} alt="FAQs" className="w-12 h-auto" />
      </Link>
    </div>
  );
}
