import React from 'react'
import Bulb from '../assets/Home/Bulb.svg'

export default function Responsibilities() {
  return (
    <>
      <hr className="border-pinkColor border-2 w-[69%]" />
      <div className="w-screen py-4 md:py-10 text-center flex flex-col items-center justify-center gap-4 md:gap-8 select-none h-max md:h-auto">
        <h1 className="text-lg sm:text-xl md:text-3xl font-semibold px-4 py-1 border-2 rounded-3xl">
          Responsibilities
        </h1>
        <div className="w-[100%] font-league-gothic flex flex-col md:flex-row justify-between items-center md:items-start h-[70%]">
          <div className="bg-resColor mt-2 md:w-[22%] w-[60%] h-full rounded-lg flex flex-col items-center py-4 px-[1%] gap-4">
            <img src={Bulb} alt="Bulb" className="w-24 h-auto" />
            <h2 className="text-xl md:text-2xl font-medium">
              Promotion and Awareness
            </h2>
            <p className="text-sm md:text-base lg:text-xl opacity-50 hover:opacity-100">
              Your role will involve promoting Quark through multiple channels.
              This includes using social media platforms, distributing posters,
              sharing information in WhatsApp groups and engaging in personal
              interactions with potential participants
            </p>
          </div>
          <div className="bg-resColor mt-2 md:w-[22%] w-[60%] h-full rounded-lg flex flex-col items-center py-4 px-[1%] gap-4">
            <img src={Bulb} alt="Bulb" className="w-24 h-auto" />
            <h2 className="text-xl md:text-2xl font-medium">Campus Outreach</h2>
            <p className="text-sm md:text-base lg:text-xl opacity-50 hover:opacity-100">
              Campus Ambassadors serve as the main point of contact for students
              with queries about Quark. They are also responsible for
              identifying potential participants for Quark on their campus
            </p>
          </div>
          <div className="bg-resColor mt-2 md:w-[22%] w-[60%] h-full rounded-lg flex flex-col items-center py-4 px-[1%] gap-4">
            <img src={Bulb} alt="Bulb" className="w-24 h-auto" />
            <h2 className="text-xl md:text-2xl font-medium">
              Social Media Management
            </h2>
            <p className="text-sm md:text-base lg:text-xl opacity-50 hover:opacity-100">
              They oversee Quark’s social media presence, keeping it lively and
              engaging to captivate followers and draw in potential participants
            </p>
          </div>
          <div className="bg-resColor mt-2 md:w-[22%] w-[60%] h-full rounded-lg flex flex-col items-center py-4 px-[1%] gap-4">
            <img src={Bulb} alt="Bulb" className="w-24 h-auto" />
            <h2 className="text-xl md:text-2xl font-medium">
              Campus Engagement Activities
            </h2>
            <p className="text-sm md:text-base lg:text-xl opacity-50 hover:opacity-100">
              CAs coordinate workshops and activities in their respective
              colleges with support from Quark, acting as the link between Quark
              and the college administration
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
