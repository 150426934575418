import React from "react";
import { FaLinkedin, FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const Card2 = (props) => {
  return (
      <div className="bg-white p-3 rounded-lg text-black ">
          <div className="flex flex-col">
              <p className="text-center text-2xl">{props.name}</p>
              <p className="text-center text-2xl">Quark Public Relations Head</p>
              <hr className="mt-1 mb-1"></hr>
              <div className="flex w-[100%] justify-evenly mt-1">
                <FaPhone className="text-xl hover:cursor-pointer" onClick={() => {
                  window.open("tel:+91-7707035735");
                }}/>
                <MdEmail className="text-xl hover:cursor-pointer" onClick={() => {
                  window.open("mailto:campusambassadorquark@gmail.com ");
                }}/>
                <FaLinkedin className="text-xl hover:cursor-pointer" onClick={() => {
                  window.open("https://www.linkedin.com/in/ayush-raj-589080286");
                }}/>
              </div>
          </div>
      </div>
  ); 
};

export default function ContactUs() {
  return (
    <>
      <hr className="border-pinkColor border-2 w-[59%] ml-auto" />
      <div className="w-screen h-[60vh] py-4 md:py-10 text-center flex flex-col items-center justify-center gap-4 md:gap-8 select-none">
        <h1 className="text-lg sm:text-xl md:text-3xl font-semibold px-4 py-1 border-2 rounded-3xl">
          Contact Us
        </h1>
        <div className="font-league-gothic flex flex-row justify-evenly w-[100%]">
          <Card2 name={"Ayush Raj"}/>
        </div>
      </div>
    </>
  );
}
