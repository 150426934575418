import React from "react";

export default function Accordion({ title, answer, isOpen, onToggle }) {
  return (
    <>
      <button
        onClick={onToggle}
        className="flex flex-row justify-between text-left w-full"
      >
        <p>{title}</p>
        <span className="text-sm flex items-center text-boxColor">Ans</span>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0 border-pink-500"
        }`}
      >
        <div className="border-2 border-pinkColor overflow-hidden bg-white text-md p-2 text-black rounded-md">
          {answer}
        </div>
      </div>
    </>
  );
}
