import React from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutUs from "../components/AboutUs";
import Benefits from "../components/Benefits";
import Responsibilities from "../components/Responsibilities";
import Testimonials from "../components/Testimonials";
import FAQs from "../components/FAQs";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Sponsors from "../components/Sponsors";

export default function HomePage() {
  return (
    <div className="bg-stroke-gradient overflow-hidden flex flex-col">
      <Navbar />
      <Sidebar />
      <div id="hero">
        <Hero />
      </div>
      <div id="aboutus">
        <AboutUs />
      </div>
      <div id="benefits">
        <Benefits />
      </div>
      <div id="responsibilities">
        <Responsibilities />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <div id="sponsors">
        <Sponsors />
      </div>
      <div id="faqs">
        <FAQs />
      </div>
      <div id="contactus">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}
