import { React, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import NavLogo from "../assets/Home/NavLogo.svg";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-navColor fixed w-full p-1 h-auto z-10">
      <div className="w-full px-2 flex justify-between items-center">
        <div className="flex-shrink-0">
          <img src={NavLogo} alt="Logo" className="w-14 h-auto" />
        </div>
        <div className="hidden md:flex items-center mr-2">
          <div className="flex text-lg">
            <Link to="aboutus" smooth={true} offset={-20} duration={500} className="cursor-pointer mr-1">
              About Us
            </Link>
            |
            <Link to="benefits" smooth={true} offset={-30} duration={500} className="cursor-pointer mx-1">
              Benefits
            </Link>{" "}
            |
            <Link to="responsibilities" smooth={true} offset={0} duration={500} className="cursor-pointer mx-1">
              Responsibilities
            </Link>{" "}
            |
            <Link
              to="testimonials"
              smooth={true}
              offset={-70} // Adjust for fixed navbar
              duration={500}
              className="cursor-pointer ml-1"
            >
              Testimonials
            </Link>
          </div>
          <motion.button
            className="bg-transparent text-white text-xl border-4 px-4 py-1 ml-4 rounded-3xl hover:bg-applyBtn"
            whileHover={{ scale: 1.05 }}
            onClick={() => window.open("https://unstop.com/internships/campus-ambassador-program-internship-birla-institute-of-technology-and-science-pilani-goa-1237351")}
          >
            Apply Now
          </motion.button>
        </div>

        <motion.button
          className="md:hidden bg-transparent text-white text-xl border-4 px-4 py-1 ml-4 rounded-3xl hover:bg-applyBtn"
          whileHover={{ scale: 1.05 }}
          onClick={() => window.open("https://unstop.com/internships/campus-ambassador-program-internship-birla-institute-of-technology-and-science-pilani-goa-1237351")}
        >
          Apply Now
        </motion.button>

        <div onClick={toggleMenu} className={`md:hidden cursor-pointer flex flex-col items-center hamburger ${isOpen ? "active" : ""}`}>
          <span className="bar block rounded w-[27px] h-[3.5px] my-[4px] mx-auto transition-all duration-300 ease-in-out bg-white"></span>
          <span className="bar block rounded w-[27px] h-[3.5px] my-[4px] mx-auto transition-all duration-300 ease-in-out bg-white"></span>
          <span className="bar block rounded w-[27px] h-[3.5px] my-[4px] mx-auto transition-all duration-300 ease-in-out bg-white"></span>
        </div>

        <div className={`fixed text-lg rounded top-[69px] right-1 w-auto bg-gray-800 text-center transition-transform duration-300 ease-in-out md:hidden ${isOpen ? "flex" : "hidden"}`}>
          <div className="flex flex-col items-center bg-navColor p-2 rounded-lg shadow-custom-shadow">
            <Link to="aboutus" smooth={true} offset={-20} duration={500} className="mx-1 my-0.5" onClick={toggleMenu}>
              About Us
            </Link>
            <Link to="benefits" smooth={true} offset={-30} duration={500} className="mx-1 my-0.5" onClick={toggleMenu}>
              Benefits
            </Link>
            <Link to="responsibilities" smooth={true} offset={0} duration={500} className="mx-1 my-0.5" onClick={toggleMenu}>
              Responsibilities
            </Link>
            <Link to="testimonials" smooth={true} offset={-70} duration={500} className="mx-1 my-0.5" onClick={toggleMenu}>
              Testimonials
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}
