import React from "react";
import FooterLogo from "../assets/Home/FooterLogo.svg";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="bg-navColor w-full inset-x-0 bottom-0 border-t-4 border-pinkColor p-6 grid grid-cols-2 items-center select-none">
      <div className="space-y-2">
        <img src={FooterLogo} alt="Logo" className="w-16 h-auto" />
        <div className="flex flex-col">
          <h4 className="font-bold">Contact Us:</h4>
          <MdEmail className="text-2xl hover:cursor-pointer" onClick={() => window.open("mailto:campusambassadorquark@gmail.com")} />
        </div>
      </div>
      <div className="flex flex-col space-y-2 items-end justify-end h-[100%]">
        <h4 className="font-bold text-pinkColor text-lg">Follow Us</h4>
        <div className="flex space-x-4">
          <FaFacebook className="text-2xl hover:cursor-pointer" onClick={() => window.open("https://www.facebook.com/share/14hW1cTuNM/")} />
          <FaInstagram className="text-2xl hover:cursor-pointer" onClick={() => window.open("https://www.instagram.com/bitsquark?igsh=MWU3NHkxZjB5dXhtMg==")} />
          <FaXTwitter className="text-2xl hover:cursor-pointer" onClick={() => window.open("https://x.com/bitsquark?t=R9qk_3Rcf1SYBeGaZHWwgg&s=09")} />
          <FaLinkedin className="text-2xl hover:cursor-pointer" onClick={() => window.open("https://www.linkedin.com/company/quark-bits-pilani-goa/")} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
