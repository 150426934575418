import React from 'react'
import topRight from '../assets/Leaderboard/Top right.png'
import bottomLeft from '../assets/Leaderboard/bottom left.png'
import quarkLogo from '../assets/Leaderboard/Quark logo.png'
import centerRectangle from '../assets/Leaderboard/center Rectangle.png'
import leaderboardRectangle from '../assets/Leaderboard/leaderboard Rectangle.png'
import leaderboard from '../assets/Leaderboard/L E A D E R B O A R D.png'
import { useNavigate } from 'react-router-dom'

export default function LeaderboardStructure() {
    const navigate = useNavigate()
  return (
    <div>
      <img src={quarkLogo} onClick={(e)=>{navigate("/")}} draggable="false" className='w-[8.862%] h-[13.747%] absolute top-0 left-0' alt=""></img>
      <img src={topRight} draggable="false" className='w-[34.127%] h-[49.185%] absolute top-0 right-0' alt=""></img>
      <img src={bottomLeft} draggable="false" className='w-[34.127%] h-[49.185%] absolute bottom-0 left-0' alt=""></img>
      <img src={centerRectangle} draggable="false" className='w-[68.981%] h-[64.256%] absolute bottom-[17.872%] left-[15.509%]' alt=""></img>
      <img src={leaderboardRectangle} draggable="false" className='w-[28.108%] h-[14.46%] absolute top-[11.303%] left-[36.243%]' alt=""></img>
      <img src={leaderboard} draggable="false" className='w-[22.685%] h-[7.598%] absolute top-[14.589%] left-[38.822%]' alt=""></img>
      <img src={quarkLogo} alt='quarkLogo' onClick={(e)=>{navigate("/")}} draggable="false" className='w-[8.862%] h-[13.747%] absolute top-0 left-0'></img>
      <img src={topRight} alt='topRight' draggable="false" className='w-[34.127%] h-[49.185%] absolute top-0 right-0'></img>
      <img src={bottomLeft} alt='bottomLeft' draggable="false" className='w-[34.127%] h-[49.185%] absolute bottom-0 left-0'></img>
      <img src={centerRectangle} alt='centerRectangle' draggable="false" className='w-[68.981%] h-[64.256%] absolute bottom-[17.872%] left-[15.509%]'></img>
      <img src={leaderboardRectangle} alt='leaderboardRectangle' draggable="false" className='w-[28.108%] h-[14.46%] absolute top-[11.303%] left-[36.243%]'></img>
      <img src={leaderboard} alt='leaderboard' draggable="false" className='w-[22.685%] h-[7.598%] absolute top-[14.589%] left-[38.822%]'></img>
      <div className='w-[60.45%] h-[48.472%] absolute top-[27.698%] left-[20.106%] overflow-y-scroll no-scrollbar text-white'></div>
    </div>
  )
}
