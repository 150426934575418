import React from 'react'
import goldMedal from '../assets/Leaderboard/gold medal.png'
import silverMedal from '../assets/Leaderboard/Silver medal.png'
import bronzeMedal from '../assets/Leaderboard/Bronze medal.png'
import userIcon from '../assets/Leaderboard/User icon.png'



export default function LeaderboardScrollbar() {
    return (
      <div className='w-[60.45%] h-[48.472%] absolute top-[27.698%] left-[20.106%] overflow-y-scroll no-scrollbar text-white'>
        <div className='flex justify-between items-center '>
          <img draggable="false" alt="Icon" src={goldMedal} className='w-[5.556%] h-[10.192%]'></img>
          <img draggable="false" alt="Icon" src={userIcon} className='w-[5.225%] h-[7.779%]'></img>
          <span className=' basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center'>
          <img draggable="false" alt="Icon" src={silverMedal} className='w-[5.556%] h-[10.192%]'></img>
          <img draggable="false" alt="Icon" src={userIcon} className='w-[5.225%] h-[7.779%]'></img>
          <span  className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center  mb-1'>
          <img draggable="false" alt="Icon" src={bronzeMedal} className='w-[5.556%] h-[10.192%]'></img>
          <img draggable="false" alt="Icon" src={userIcon} className='w-[5.225%] h-[7.779%]'></img>
          <span  className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center mb-2'>
          <span className='w-[5.556%] h-[10.192%] text-center'>4</span>
          <img src={userIcon} alt="Icon" draggable="false" className='w-[5.225%] h-[7.779%]'></img>
          <span  className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div> 
        <div className='flex justify-between items-center mb-2'>
          <span className='w-[5.556%] h-[10.192%] text-center'>5</span>
          <img src={userIcon} alt="Icon" draggable="false" className='w-[5.225%] h-[7.779%]'></img>
          <span  className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center mb-2'>
          <span className='w-[5.556%] h-[10.192%] text-center'>6</span>
          <img src={userIcon} alt="Icon" draggable="false" className='w-[5.225%] h-[7.779%]'></img>
          <span  className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center mb-2'>
          <span className='w-[5.556%] h-[10.192%] text-center'>7</span>
          <img src={userIcon} alt="Icon" draggable="false" className='w-[5.225%] h-[7.779%]'></img>
          <span  className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center mb-2'>
          <span className='w-[5.556%] h-[10.192%] text-center'>8</span>
          <img src={userIcon} alt="Icon" draggable="false" className='w-[5.225%] h-[7.779%]'></img>
          <span  className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center mb-2'>
          <span className='w-[5.556%] h-[10.192%] text-center'>9</span>
          <img src={userIcon} alt="Icon" draggable="false" className='w-[5.225%] h-[7.779%]'></img>
          <span className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>
        <div className='flex justify-between items-center'>
          <span className='w-[5.556%] h-[10.192%] text-center'>10</span>
          <img src={userIcon} alt="Icon" draggable="false" className='w-[5.225%] h-[7.779%]'></img>
          <span className='basis-[68.927%] bg-gradient-to-r from-[#E0419B] to-[#A480FF] whitespace-pre rounded-md'> </span>
        </div>

      </div>
    )
  }