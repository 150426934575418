import { motion } from 'framer-motion';
import { useState, useEffect, useCallback, useMemo } from 'react';

function Hero() {
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  
  const words = useMemo(() => ['STEP UP', 'STAND OUT'], []);
  const period = 2000;
  const deletingSpeed = 100;
  const typingSpeed = 150;

  const tick = useCallback(() => {
    let i = loopNum % words.length;
    let fullText = words[i];
    let updatedText = isDeleting 
      ? fullText.substring(0, text.length - 1) 
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (!isDeleting && updatedText === fullText) {
      setTimeout(() => setIsDeleting(true), period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(prevLoopNum => prevLoopNum + 1);
    }
  }, [text, isDeleting, loopNum, words, period]);

  useEffect(() => {
    let timer = setTimeout(() => {
      tick();
    }, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(timer);
  }, [tick, isDeleting, deletingSpeed, typingSpeed]);

  return (
    <div className="w-screen h-screen bg-hero bg-cover bg-center py-4 md:py-10 flex flex-col items-center justify-center select-none">
      <div className="flex flex-col w-[85%] md:w-[70%] mt-12">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-5.5xl font-semibold w-[30%]">
          College Ambassador Program
        </h1>
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-pinkColor font-league-spartan mt-4 lg:mt-2">
          Quark, BITS Goa
        </h2>
        <h2 className="text-lg sm:text-xl md:text-3xl font-league-gothic w-[50%] lg:w-[20%]">
          Be the face of Quark in Your Campus!
        </h2>
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-league-spartan font-extrabold mt-4 lg:mt-2 relative">
          <span className="inline-block whitespace-nowrap">
            {text}
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, repeat: Infinity, repeatType: 'reverse' }}
              className="ml-1 inline-block"
            >
              |
            </motion.span>
          </span>
        </h2>
      </div>
      <div className="bg-white-35 rounded-2xl w-[85%] md:w-[70%] mt-6 lg:mt-4 p-2 flex justify-evenly flex-col md:flex-row">
        <p className="text-4xl sm:text-5.5xl md:text-6xl text-pinkColor font-league-gothic text-center flex flex-col">
          1000+
          <span className="text-white text-3xl">colleges</span>
        </p>
        <p className="text-4xl sm:text-5.5xl md:text-6xl text-pinkColor font-league-gothic text-center flex flex-col">
          5000+ <span className="text-white text-3xl">applicants</span>
        </p>
        <p className="text-4xl sm:text-5.5xl md:text-6xl text-pinkColor font-league-gothic text-center flex flex-col">
          3500+ <span className="text-white text-3xl">CA's</span>
        </p>
      </div>
    </div>
  );
}

export default Hero;
