import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "./Card";

export default function Testimonials() {
  var settings = {
    className: "center",
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      nomen: "Anand Prakash",
      testimonial:
        "I was in my 2nd year at HBTU Kanpur when I joined as a campus ambassador for Quark'19. The experience was really amazing working with the Quark team. It helped me build a solid network with peers from different colleges.",
    },
    {
      nomen: "Ajay Thakur",
      testimonial: "Being a Quark CA helped me grow. I became better at talking to people and organizing events. The other ambassadors were so helpful. I'll always remember this experience",
    },
    {
      nomen: "Khushi Singh",
      testimonial:
        "Being a campus ambassador for Quark was an incredible journey. The opportunity to network with peers across the country, was invaluable. It honed my leadership skills and taught me the art of time management. I'm deeply grateful to my mentors and the Quark team for this enriching experience.",
    },
    {
      nomen: "Md Faiz",
      testimonial:
        "Being a Quark ambassador as a 2nd year engineering student at VIT Bhopal was great. I learned to balance studies with new responsibilities, met amazing people, and applied my skills to real projects",
    },
    {
      nomen: "Palak",
      testimonial:
        "Quark was a mix of learning and enjoyment. I picked up new skills like promoting events and running workshops. It made me more interested in tech and helped me grow as a person. Thanks to everyone at Quark for this great experience",
    },
  ];

  return (
    <>
      <hr className="border-pinkColor border-2 w-[59%] ml-auto" />
      <h1 className="text-lg sm:text-xl md:text-3xl text-center place-self-center font-semibold lg:w-[23%] md:w-[30%] py-1 mt-10 border-2 rounded-3xl">Testimonials</h1>
      <div className="slider-container">
        <Slider {...settings} className="my-10 select-none">
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <Card testimonial={testimonial.testimonial} nomen={testimonial.nomen} />
            </div>
          ))}
        </Slider>
      </div>

      {/* </div> */}
    </>
  );
}
