import React from "react";
import unstop from "../assets/Home/unstop.svg";
import stockedge from "../assets/Home/stockedge.png";

const Sponsors = () => {
  return (
    <>
      <hr className="border-pinkColor border-2 w-[69%]" />
      <div className="w-screen py-4 md:py-8 text-center flex flex-col items-center justify-center gap-2 md:gap-4 select-none">
        <h1 className="text-lg sm:text-xl md:text-3xl font-semibold px-4 py-1 border-2 rounded-3xl">Sponsors</h1>
        <div className="flex flex-col md:flex-row w-[100%] justify-evenly items-center">
          <img src={unstop} alt="unstop" className="w-[200px] my-2 p-3 h-auto bg-white hover:cursor-pointer" onClick={() => window.open("https://unstop.com/")} />
          <img src={stockedge} alt="stockedge" className="w-[200px] my-2 p-3 h-auto bg-white hover:cursor-pointer" onClick={() => window.open("https://stockedge.com/")} />
        </div>
      </div>
    </>
  );
};

export default Sponsors;
