import React from "react";
import ImageGallery from "./ImageGallery";

import image1 from "../assets/Home/AboutUs/AboutUsImage1.png";
import image2 from "../assets/Home/AboutUs/AboutUsImage2.png";
import image3 from "../assets/Home/AboutUs/AboutUsImage3.png";
import image4 from "../assets/Home/AboutUs/AboutUsImage4.png";
import image5 from "../assets/Home/AboutUs/AboutUsImage5.png";
import image6 from "../assets/Home/AboutUs/AboutUsImage6.png";

const AboutUs = () => {
  const images = [image1, image2, image3, image4, image5, image6];
  return (
    <>
      <hr className="border-pinkColor border-2 w-[69%]" />
      <div className="w-screen py-4 md:py-8 text-center flex flex-col items-center justify-center gap-2 md:gap-4 select-none">
        <h1 className="text-lg sm:text-xl md:text-3xl font-semibold px-4 py-1 border-2 rounded-3xl">
          About Us
        </h1>
        <p className="w-[90%] md:w-[87%] md:font-semibold lg:text-lg mb-4">
          Quark, the annual technical festival at BITS Pilani's Goa Campus,
          offers students an exciting opportunity to join the Campus Ambassador
          Program. As an Ambassador, you'll be the event's representative on
          your campus, promoting Quark's events, workshops, and competitions.
          This role not only boosts your leadership and communication skills but
          also provides exclusive perks like priority access to events, special
          recognition, and networking with industry professionals. Join us in
          driving innovation and making Quark a success!
        </p>
        <div className="w-[90%] md:w-[87%] aspect-[16/9] max-w-3xl">
          <ImageGallery images={images} />
        </div>
      </div>
    </>
  );
};

export default AboutUs;
