import React from 'react'
import LeaderboardStructure from '../components/Leaderboard_Structure'
import LeaderboardScrollbar from '../components/Leaderboard_Scrollbar'

export default function LeaderboardPage() {
 return (
    <div className='bg-[#191042] h-screen select-none'>
      <LeaderboardStructure />
      <LeaderboardScrollbar />
    </div>
  )
}
